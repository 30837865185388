import { createRouter, createWebHistory, RouteRecordRaw, RouterLink } from "vue-router";
import { accessGuard_request, accessGuard_Confirm, accessGuard_Result, accessGuard_Detail } from './accessGuard'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/scraping/agree"
  },
  {
    path: '/scraping',
    name: 'scraping',
    component: () => import(/* webpackChunkName: "MainView" */ '../views/MainView.vue'),
    children: [
      {
        path: 'agree',
        name: 'agree',
        component: () => import(/* webpackChunkName: "Agree" */ '../components/organisms/Agree.vue'),
        beforeEnter: accessGuard_request
      },
      // {
      //   path: 'method',
      //   name: 'method',
      //   component: () => import(/* webpackChunkName: "Method" */ '../components/organisms/Method.vue'),
      //   beforeEnter: accessGuard_request
      // },
      {
        path: 'request',
        name: 'request',
        component: () => import(/* webpackChunkName: "Request" */ '../components/organisms/Request.vue'),
        // beforeEnter: accessGuard_request
      },
      {
        path: 'sms',
        name: 'sms',
        component: () => import(/* webpackChunkName: "Sms" */ '../components/organisms/SmsAuth.vue'),
        beforeEnter: accessGuard_Confirm
      },
      {
        path: 'confirm',
        name: 'confirm',
        component: () => import(/* webpackChunkName: "Confirm" */ '../components/organisms/Confirm.vue'),
        //beforeEnter: accessGuard_Confirm
      },
      {
        path: 'result',
        name: 'result',
        component: () => import(/* webpackChunkName: "Result" */ '../components/organisms/Result.vue'),
        beforeEnter: accessGuard_Result
      },
      {
        path: 'detail',
        name: 'detail',
        component: () => import(/* webpackChunkName: "Detail" */ '../components/organisms/Detail.vue'),
        beforeEnter: accessGuard_Detail
      },
      {
        path: '/error/:code',
        name: 'error',
        component: () => import(/* webpackChunkName: "Error" */ '../components/organisms/Error.vue'),
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;