import { NavigationGuard, NavigationGuardNext } from "vue-router";
import api from '@/api/Scraping'
import { ref, reactive } from 'vue'

export { accessGuard_request, accessGuard_Confirm, accessGuard_Result, accessGuard_Detail }

/* router Guard - Agree, Method, Request */
const accessGuard_request: NavigationGuard = async (to, from, next) => {
    const { query } = to;

    if (query.receiptId) {
        const existsResult = reactive<ExistsResult>(Object.create(null));
        const pass = ref<boolean>(false);

        Object.assign(existsResult, await CheckReceiptId(query.receiptId.toString(), "request"));
        pass.value = existsResult.isExistsRequest && !existsResult.isExistsRequestLog;

        pass.value ? next()
        : !existsResult.isExistsRequest ? invalidAccess(400, next)
        : existsResult.isExistsRequestLog ? invalidAccess(410, next)
        : invalidAccess(401, next);
    } else {
        invalidAccess(401, next);
    }
};

/* router Guard - Confirm, smsConfirm */
const accessGuard_Confirm: NavigationGuard = async (to, from, next) => {
    const { query } = to;

    if (from.name === 'request') {
        next();
    } else if (query.receiptId) {
        next({ name: 'request', query: { receiptId: query.receiptId } });
    } else {
        invalidAccess(401, next);
    }
}

/* router Guard - Reulst */
const accessGuard_Result: NavigationGuard = async (to, from, next) => {
    if (from.name === 'confirm' || from.name === 'sms' || from.name === 'detail') {
        next();
    } else {
        invalidAccess(401, next);
    }
}

/* router Guard - detail */
const accessGuard_Detail: NavigationGuard = async (to, from, next) => {
    if (from.name === 'result') {
        next();
    } else {
        invalidAccess(401, next);
    }
}

/* Error Page */
const invalidAccess = (code: number, next: NavigationGuardNext) => {
    next({ name: 'error', params: { code: code } })
};

/* 접수번호 체크 */
const CheckReceiptId = async (receiptId: string, type: string) => {
    const result = await api.CheckReceiptId(receiptId.toString(), type).then((res: ValidReceiptIdResult) => {
        return res.data;
    }).catch((error: Error) => {
        console.log("%cError Name : %s", "background: ed;", error.name);
        console.log("%cError Stack : %s", "background: red;", error.stack);
        console.log("%cError Message : %s", "background: red;", error.message);
    });

    return result;
};
